<template>
    <TopNavigation title="" hideSecondNavbar="true" />  
    <div id="home"></div>
    <div class="row pb-4">
        <div class="col-12 p-0">

            <!-- Large screen   -->
            <div class="d-none d-lg-block">
                <img alt="Vue logo" src="../assets/home/wave.svg" class="img-fluid">
                <div style="z-index: 1000; position: absolute; top: 32%; margin-left: 150px">
                    <div class="imgTitle">What is SignOn?</div>
                    <div class="imgDesc">
                        A simple, fast and secure way to sign documents online.<br />
                        eSignature <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" />
                        Workflow <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" />
                        API <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" />
                        Form Designer
                    </div>
                    <div class="pt-4">
                        <button class="btn btn-secondary btn-lg me-4" @click="goTrial" style="width: 200px;">Free Trial</button>
                        <button class="btn btn-outline-secondary btn-lg" @click="goVerifyDoc" style="width: 200px;">Verify Doc</button>
                    </div>
                </div>
                
            </div>

            <!-- Small screen -->
            <div class="d-lg-none">
                <img alt="Vue logo" src="../assets/home/wave.svg" style="height: 600px; width: 100%; object-fit: cover">
                <div style="z-index: 1000; position: absolute; top: 150px; margin-left: 25px; padding-right: 25px;">
                    <div class="imgTitle">What is SignOn ?</div>
                    <div class="imgDesc">
                        A simple, fast &amp; secure space to sign online<br />
                        sign online <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" />
                        workflow <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" />
                        form designer
                    </div>
                    <div class="row pt-5">
                        <div class="col-12">
                            <div class="d-grid">
                                <button class="btn btn-secondary btn-lg btn-block mb-4" @click="goTrial">Free Trial</button>
                                <!-- <button class="btn btn-outline-secondary btn-lg btn-block" @click="$router.push({ path: '/doc/verify' })">Verify Doc</button> -->
                                <button class="btn btn-outline-secondary btn-lg btn-block" @click="goVerifyDoc">Verify Doc</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <!-- <div class="homeContainer">
        <div class="row mx-4 pb-5">
            <div class="col-12 text-center secTitle">How it works</div>

            <div class="col-12 col-lg-6 boxFlow border shadow-sm mb-1 bg-white text-center"><img src="../assets/home/img_upload.jpeg" class="img-fluid" /></div>
            <div class="col-12 col-lg-6 boxTextFlow">
                <table class="table table-borderless table-sm mb-5" border="0">
                    <tbody>
                        <tr>
                            <td width="55px">
                                <div class="step-number bg-gray-1"><div class="label">1</div></div>
                            </td>
                            <td class="lblStepSubTitle pt-2">Upload your PDF file</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td class="lblStepSubDesc">Choose your PDF file to upload.</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="col-2 d-none d-lg-block"></div>
            <div class="col-8 d-none d-lg-block my-4">
                <img alt="Line" src="../assets/home/line_right.svg" class="img-fluid">
            </div>
            <div class="col-2 d-none d-lg-block"></div>

            <div class="col-12 col-lg-6 boxFlow border shadow-sm d-lg-none mb-1 bg-white text-center">
                <img src="../assets/home/img_addSignee.png" class="img-fluid"/>
            </div>
            <div class="col-12 col-lg-6 boxTextFlow">
                <table class="table table-borderless table-sm mb-5" border="0">
                    <tbody>
                        <tr>
                            <td width="55px">
                                <div class="step-number bg-gray-1"><div class="label">2</div></div>
                            </td>
                            <td class="lblStepSubTitle pt-2">Add signee to your project</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td class="lblStepSubDesc">Enter recipient email to receive your sign request.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-12 col-lg-6 boxFlow border shadow-sm d-none d-lg-block bg-white text-center">
                <img src="../assets/home/img_addSignee.png" class="img-fluid"/>
            </div>
            

            <div class="col-2 d-none d-lg-block"></div>
            <div class="col-8 d-none d-lg-block my-4">
                <img alt="Line" src="../assets/home/line_left.svg" class="img-fluid">
            </div>
            <div class="col-2 d-none d-lg-block"></div>

            <div class="col-12 col-lg-6 boxFlow border shadow-sm mb-1 bg-white text-center">
                <img src="../assets/home/img_object.jpeg" class="img-fluid"/>
            </div>
            <div class="col-12 col-lg-6 boxTextFlow">
                <table class="table table-borderless table-sm mb-5" border="0">
                    <tbody>
                        <tr>
                            <td width="55px">
                                <div class="step-number bg-gray-1"><div class="label">3</div></div>
                            </td>
                            <td class="lblStepSubTitle pt-2">Locate your field</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td class="lblStepSubDesc">Place the signature, auto-fill and standard fields to the respective location in your PDF file.</td>
                        </tr>
                    </tbody>
                </table>
            </div>


            <div class="col-2 d-none d-lg-block"></div>
            <div class="col-8 d-none d-lg-block my-4">
                <img alt="Line" src="../assets/home/line_right.svg" class="img-fluid">
            </div>
            <div class="col-2 d-none d-lg-block"></div>

            <div class="col-12 col-lg-6 boxFlow border shadow-sm d-lg-none mb-1 bg-white text-center">
                <img src="../assets/home/img_invite.jpeg" class="img-fluid"/>
            </div>
            <div class="col-12 col-lg-6 boxTextFlow">
                <table class="table table-borderless table-sm" border="0">
                    <tbody>
                        <tr>
                            <td width="55px">
                                <div class="step-number bg-gray-1"><div class="label">4</div></div>
                            </td>
                            <td class="lblStepSubTitle pt-2">Send sign request</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td class="lblStepSubDesc">Invite signee to sign your document.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-12 col-lg-6 boxFlow border shadow-sm d-none d-lg-block bg-white text-center">
                <img src="../assets/home/img_invite.jpeg" class="img-fluid"/>
            </div>

        </div>
    </div> -->

    <div class="homeContainer">

        <div class="row mx-4 pb-5">
            <div class="col-12 text-center secTitle">How it works</div>

            <div class="col-12 col-lg-6 boxFlow border shadow-sm mb-1 bg-white text-center"><img src="../assets/home/img_upload.jpeg" class="img-fluid" /></div>
            <div class="col-12 col-lg-6 boxTextFlow">
                <table class="table table-borderless table-sm mb-5" border="0">
                    <tbody>
                        <tr>
                            <td width="55px">
                                <div class="step-number bg-gray-1"><div class="label">1</div></div>
                            </td>
                            <td class="lblStepSubTitle pt-2">Upload your PDF file</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td class="lblStepSubDesc">Choose your PDF file to upload.</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="col-4 d-none d-lg-block"></div>
            <div class="col-4 d-none d-lg-block my-4">
                <img alt="Line" src="../assets/home/line_right.svg" class="img-fluid">
            </div>
            <div class="col-4 d-none d-lg-block"></div>

            <div class="col-12 col-lg-6 boxFlow border shadow-sm d-lg-none mb-1 bg-white text-center">
                <img src="../assets/home/img_addSignee.png" class="img-fluid"/>
            </div>
            <div class="col-12 col-lg-6 boxTextFlow">
                <table class="table table-borderless table-sm mb-5" border="0">
                    <tbody>
                        <tr>
                            <td width="55px">
                                <div class="step-number bg-gray-1"><div class="label">2</div></div>
                            </td>
                            <td class="lblStepSubTitle pt-2">Add signee to your project</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td class="lblStepSubDesc">Enter recipient email to receive your sign request.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-12 col-lg-6 boxFlow border shadow-sm d-none d-lg-block bg-white text-center">
                <img src="../assets/home/img_addSignee.png" class="img-fluid"/>
            </div>
            

            <div class="col-4 d-none d-lg-block"></div>
            <div class="col-4 d-none d-lg-block my-4">
                <img alt="Line" src="../assets/home/line_left.svg" class="img-fluid">
            </div>
            <div class="col-4 d-none d-lg-block"></div>

            <div class="col-12 col-lg-6 boxFlow border shadow-sm mb-1 bg-white text-center">
                <img src="../assets/home/img_object.jpeg" class="img-fluid"/>
            </div>
            <div class="col-12 col-lg-6 boxTextFlow">
                <table class="table table-borderless table-sm mb-5" border="0">
                    <tbody>
                        <tr>
                            <td width="55px">
                                <div class="step-number bg-gray-1"><div class="label">3</div></div>
                            </td>
                            <td class="lblStepSubTitle pt-2">Locate your field</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td class="lblStepSubDesc">Place the signature, auto-fill and standard fields to the respective location in your PDF file.</td>
                        </tr>
                    </tbody>
                </table>
            </div>


            <div class="col-4 d-none d-lg-block"></div>
            <div class="col-4 d-none d-lg-block my-4">
                <img alt="Line" src="../assets/home/line_right.svg" class="img-fluid">
            </div>
            <div class="col-4 d-none d-lg-block"></div>

            <div class="col-12 col-lg-6 boxFlow border shadow-sm d-lg-none mb-1 bg-white text-center">
                <img src="../assets/home/img_invite.jpeg" class="img-fluid"/>
            </div>
            <div class="col-12 col-lg-6 boxTextFlow">
                <table class="table table-borderless table-sm" border="0">
                    <tbody>
                        <tr>
                            <td width="55px">
                                <div class="step-number bg-gray-1"><div class="label">4</div></div>
                            </td>
                            <td class="lblStepSubTitle pt-2">Send sign request</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td class="lblStepSubDesc">Invite signee to sign your document.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-12 col-lg-6 boxFlow border shadow-sm d-none d-lg-block bg-white text-center">
                <img src="../assets/home/img_invite.jpeg" class="img-fluid"/>
            </div>

        </div>
    </div>

    <!-- Enerson requested to hide tmp
    <div class="homeContainer">
        <div class="row">
            <div class="col-12 text-center secTitle">How to use</div>

            <div class="col-12 col-lg-6 d-grid">
                <div class="homeBox">
                    <div class="row">
                        <div class="col-12 col-lg-7 pb-4">
                            <div class="lblStep">STEP 1</div>
                            <div class="lblStepTitle">Upload</div>
                            <div class="lblStepDesc">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Rerum minima facere nulla officiis labore atque nisi necessitatibus, quidem cumque deleniti aut accusantium dolorum, ipsum fugiat, corporis tempora cupiditate nihil molestias.</div>
                        </div>
                        <div class="col-12 col-lg-5 pb-2">
                            <table height="100%" border="0" align="center">
                                <tr>
                                    <td class="align-bottom"><img alt="Vue logo" src="../assets/home/upload.jpg" style="border-radius: 10px; height: 155px; width: 155px; object-fit:cover"></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 col-lg-6 d-grid">
                <div class="homeBox">
                    <div class="row">
                        <div class="col-12 col-lg-7 pb-4">
                            <div class="lblStep">STEP 2</div>
                            <div class="lblStepTitle">Design</div>
                            <div class="lblStepDesc">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Rerum minima facere nulla officiis labore atque nisi necessitatibus, quidem cumque cupiditate nihil molestias.</div>
                        </div>
                        <div class="col-12 col-lg-5 pb-2">
                            <table height="100%" border="0" align="center">
                                <tr>
                                    <td class="align-bottom"><img alt="Vue logo" src="../assets/home/edit1.jpg" style="border-radius: 10px; height: 155px; width: 155px; object-fit:cover"></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 col-lg-6 d-grid">
                <div class="homeBox">
                    <div class="row">
                        <div class="col-12 col-lg-7 pb-4">
                            <div class="lblStep">STEP 3</div>
                            <div class="lblStepTitle">Invite</div>
                            <div class="lblStepDesc">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Rerum minima cumque deleniti aut accusantium dolorum, ipsum fugiat, corporis tempora cupiditate nihil molestias.</div>
                        </div>
                        <div class="col-12 col-lg-5 pb-2">
                            <table height="100%" border="0" align="center">
                                <tr>
                                    <td class="align-bottom"><img alt="Vue logo" src="../assets/home/send_email.jpg" style="border-radius: 10px; height: 155px; width: 155px; object-fit:cover"></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 col-lg-6 d-grid">
                <div class="homeBox">
                    <div class="row">
                        <div class="col-12 col-lg-7 pb-4">
                            <div class="lblStep">STEP 4</div>
                            <div class="lblStepTitle">Sign</div>
                            <div class="lblStepDesc">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Rerum minima facere nulla  atque nisi necessitatibus, quidem cumque deleniti aut accusantium dolorum, ipsum fugiat, corporis tempora cupiditate nihil molestias.</div>
                        </div>
                        <div class="col-12 col-lg-5 pb-2">
                            <table height="100%" border="0" align="center">
                                <tr>
                                    <td class="align-bottom"><img alt="Vue logo" src="../assets/home/digital_signature.jpg" style="border-radius: 10px; height: 155px; width: 155px; object-fit:cover"></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
    </div> -->

    <!-- Enerson requested to hide tmp
    <div class="homeContainer">
        <div class="row">
            <div class="col-12 text-center secTitle">How we protect your doc</div>

            <div class="col-12 col-lg-6 d-grid">
                <div class="homeBlueBox border shadow-sm">
                    <div class="boxTitle text-white">Decentralize documents</div>
                    <div class="boxDesc text-white">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, illum possimus iusto ullam amet eligendi consequuntur, fuga reprehenderit molestias nostrum repellendus placeat. Temporibus error incidunt, sequi velit voluptatem ipsam nam.</div>
                    <div class="boxButton">
                        <button class="btn btn-light btn-lg btn-block mb-4" style="color: #53BDEB">Learn more</button>
                    </div>
                </div>
            </div>

            <div class="col-12 col-lg-6 d-grid">
                <div class="homeBox border shadow-sm">
                    <div class="boxTitle">Encrypted documents</div>
                    <div class="boxDesc">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, illum possimus iusto ullam amet eligendi consequuntur, fuga reprehenderit molestias nostrum repellendus placeat. Temporibus error incidunt, sequi velit voluptatem ipsam nam.</div>
                    <div class="boxButton">
                        <button class="btn btn-secondary btn-lg btn-block mb-4">Learn more</button>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <div class="row">
        <div class="greyContainer">
            
            <div class="row">
                <div class="col-12 text-center secTitle">Why SignOn</div>
                <div class="col-12 mb-3">
                    <div class="greyContent">
                        <div class="mb-3">Many do not understand the difference of eSignature and "Picture Signature". While eSignature has legal binding status in many countries, it is not the same for picture signature, which can be forged easily without verification, tracing and tracking.</div>
                        <div class="mb-3">eSignatures are legal, trusted and enforceable in many countries around the world. The usage was boosted during Covid19 Pandemic around year 2021 as many businesses continued to operate as usual but the country was being locked down. Many people were forced to work from home (WFH), some of them started to use eSignature to sign agreements, contracts and forms online.</div>
                        <div class="mb-3">eSignature is extremely convenient, especially useful for multinational companies (MNC) with staff working at different locations and beneficials for all kinds of online agreements. There are plenty of software in the market that provide eSignature features, such as the very user-friendly "Preview" that comes pre-installed on all MacOS. However, most of the eSignature tools are merely a pdf reader with simple editor capability, to scan or upload your physically signed signature on a piece of white paper and then paste on the pdf documents to simulate the e-signing process.</div>
                    </div>
                </div>

                <div class="col-12 bg-grey mb-4">
                    <div class="greyContent text-white pt-3">
                        <div class="mb-3 fw-bold">Common Fraud Examples</div>
                        <div class="mb-3">
                            <ul>
                                <li>You realized your signed pdf document is being modified by someone and later distributed to others without your permission?</li>
                                <li>We can replace 2 pages out of 100 pages of a physically signed agreement, after it is already legally stamped, provided both parties agree to swap the pages. What if we swap additional pages? Who is holding the records?</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-12 mt-3">
                    <div class="greyContent">
                        <div class="row">
                            <div class="col-12 col-lg-6 d-grid mb-3">
                                <div class="boxFlow border shadow-sm px-4 pt-4">
                                    <div class="fw-bold mb-2">This is where the problems start</div>
                                    <div>
                                        <ul>
                                            <li>Physical signatures can be forged.</li>
                                            <li>eSignatures can be forged as well, copy and paste.</li>
                                            <li>Physical documents can be altered after you sign.</li>
                                            <li>Digital paper (pdf document) can be modified after you sign.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-lg-6 d-grid mb-3">
                                <div class="boxFlow border shadow-sm p-3 bg-primary text-white px-4 pt-4">
                                    <div class="fw-bold mb-2">There is no unbreakable lock, we need a simple yet effective solution to</div>
                                    <div>
                                        <ul>
                                            <li>Validate the origin of the documents.</li>
                                            <li>Verify the senders, organizations and signees.</li>
                                            <li>Trace all the signing processes with full audit logs.</li>
                                            <li>Hold the encrypted pdf documents and records by a third party without conflict of interests.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    

                <div class="col-12 mt-3">
                    <div class="greyContent">
                        <div class="mb-3">This is what sets us apart from the competitions. SignOn solves all the problems above for you. Furthermore, we are launching a series of integrated solutions to assist your business, such as API, Workflow, Form Designer, etc. Forget about spending hundreds of thousands before you start your business, but scale with our solutions, grow together with us.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div id="secWhoWeAre"></div>
    <div class="homeContainer pb-4">
        <div class="row">
            <div class="col-12 text-center secTitle">Who we are</div>
            <div class="col-12 boxFlow border shadow-sm d-none d-lg-block">
                <div class="row">
                    <div class="col-lg-6 whoWeAreImg"></div>
                    <div class="col-lg-6 p-4 lblStepSubDesc">
                        <div class="mb-2">We are a new start up from Malaysia, Asia Pacific.</div>
                        <div class="mb-2">Our first product - eSignature is 100% built and made in Malaysia.</div>
                        <div class="mb-2">It is free and we are trying our best to make it as user friendly as possible.</div>
                        <div class="mb-2">We welcome your <a href="mailto: wecare@signon.my" class="text-primary">feedback</a>, <a href="mailto: wecare@signon.my" class="text-primary">suggestions</a>, and <a href="mailto: wecare@signon.my" class="text-primary">investments</a>.</div> 
                        <div class="mb-2">Please try it <a href="https://app.signon.my/auth/signup" class="text-primary">here</a>, and share the app with your friends and colleagues.</div>
                        <div class="mb-2">Thanks!</div>
                    </div>
                </div>
            </div>

            <div class="d-lg-none">
                <div class="col-12 mb-2">
                    <img src="../assets/home/penmanship.jpg" class="img-fluid" style="border-radius: 10px" />
                </div>

                <div class="col-12 p-4 lblStepSubDesc">
                    <div class="mb-2">We are a new start up from Malaysia, Asia Pacific.</div>
                    <div class="mb-2">Our first product - eSignature is 100% built and made in Malaysia.</div>
                    <div class="mb-2">It is free and we are trying our best to make it as user friendly as possible.</div>
                    <div class="mb-2">We welcome your <a href="mailto: wecare@signon.my" class="text-primary">feedback</a>, <a href="mailto: wecare@signon.my" class="text-primary">suggestions</a>, and <a href="mailto: wecare@signon.my" class="text-primary">investments</a>.</div> 
                    <div class="mb-2">Please try it <a href="https://app.signon.my/auth/signup" class="text-primary">here</a>, and share the app with your friends and colleagues.</div>
                    <div class="mb-2">Thanks!</div>
                </div>
            </div>
            
        </div>
    </div>

    <div id="secExclusive"></div>
    <div class="homeContainer pb-4">
        <div class="row">
            <div class="col-12 text-center secTitle">Exclusive pre-launch deal</div>
            <div class="col-12 boxFlow border shadow-sm d-none d-lg-block">
                <div class="row">
                    <div class="col-lg-6 exclusiveImg"></div>
                    <div class="col-lg-6 p-4 lblStepSubDesc">
                        <div class="mb-2">SignOn's eSignature goes live in April 2023. </div>
                        <div class="mb-2">We are still touching up the user interface, description and documentations, but it should not affect the productivity and usability.</div> 
                        <div>As a token of appreciation, we are giving away 100 credits free to anyone who sign up today, a limited-time offer.</div>
                    </div>
                </div>
            </div>

            <div class="d-lg-none">
                <div class="col-12 mb-2">
                    <img src="../assets/home/rocket.jpg" class="img-fluid" style="border-radius: 10px" />
                </div>

                <div class="col-12 p-4 lblStepSubDesc">
                    <div class="mb-2">SignOn's eSignature goes live in April 2023. </div>
                    <div class="mb-2">We are still touching up the user interface, description and documentations, but it should not affect the productivity and usability.</div> 
                    <div>As a token of appreciation, we are giving away 100 credits free to anyone who sign up today, a limited-time offer.</div>
                </div>
            </div>
        </div>
    </div>


    <!-- old contact us
    <div class="homeContainer pt-0">
        <div class="row">
            <div class="col-12 text-center secTitle">Pricing</div>

            <div class="col-12 mb-2 text-center">
                <div class="btn-group">
                    <button type="button" @click="payInYear = false" class="btn btn-lg" :class="payInYear === false ? 'btn-secondary' : 'btn-outline-secondary'">Pay Monthly</button>
                    <button type="button" @click="payInYear = true" class="btn btn-lg" :class="payInYear === true ? 'btn-secondary' : 'btn-outline-secondary'">Pay Yearly</button>
                </div> 
            </div>

            <div class="col-12 mb-4 text-center">
                FREE one-time 10 credits upon successful activation
                <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" />
                FREE one-time 10 credits upon successful KYC
            </div>

            <div class="col-12">
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-3 d-grid">
                        <div class="homeBox border shadow-sm">
                            <div class="text-center mb-1 boxDesc">
                                <span>Free</span>
                            </div>
                            <div class="text-center">
                                <span class="boxTitle ps-1">FOC</span>
                            </div>
                            <div class="text-center"><hr /></div>
                            <div class="boxFontColor mb-2">
                                <fa icon="check" size="lg" class="me-3" color="#53BDEB"/><span class="fw-bold">2</span> credits / user
                            </div>
                            <div class="boxFontColor mb-2">
                                <fa icon="check" size="lg" class="me-3" color="#53BDEB"/><span class="fw-bold">100MB</span> storage / user
                            </div>
                            <div class="boxButton d-grid">
                                <button class="btn btn-secondary btn-lg me-4" @click="goTrial" style="width: 200px;">Sign Up</button>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-3 d-grid">
                        <div class="homeBox border shadow-sm">
                            <div class="text-center mb-1 boxDesc">
                                <span>Plan 30</span>
                            </div>
                            <div class="text-center">
                                <span class="fw-bold text-uppercase">USD</span>
                                <span v-if="payInYear === false" class="boxTitle ps-1">5</span>
                                <span v-if="payInYear === true" class="boxTitle ps-1">55</span>
                                <span v-if="payInYear === false"> / month</span>
                                <span v-if="payInYear === true"> / year</span>
                            </div>
                            <div class="text-center"><hr /></div>
                            <div class="boxFontColor mb-2">
                                <fa icon="check" size="lg" class="me-3" color="#53BDEB" /><span class="fw-bold">30</span> credits / user
                            </div>
                            <div class="boxFontColor mb-2">
                                <fa icon="check" size="lg" class="me-3" color="#53BDEB"/><span class="fw-bold">1GB</span> storage / user
                            </div>
                            <div class="boxButton d-grid">
                                <button class="btn btn-secondary btn-lg me-4" @click="goTrial" style="width: 200px;">Sign Up</button>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-3 d-grid">
                        <div class="homeBox border shadow-sm">
                            <div class="text-center mb-1 boxDesc">
                                <span>Plan 300</span>
                            </div>
                            <div class="text-center">
                                <span class="fw-bold text-uppercase">USD</span>
                                <span v-if="payInYear === false" class="boxTitle ps-1">42</span>
                                <span v-if="payInYear === true" class="boxTitle ps-1">464</span>
                                <span v-if="payInYear === false"> / month</span>
                                <span v-if="payInYear === true"> / year</span>
                            </div>
                            <div class="text-center"><hr /></div>
                            <div class="boxFontColor mb-2">
                                <fa icon="check" size="lg" class="me-3" color="#53BDEB"/><span class="fw-bold">300</span> credits / user
                            </div>
                            <div class="boxFontColor mb-2">
                                <fa icon="check" size="lg" class="me-3" color="#53BDEB"/><span class="fw-bold">5GB</span> storage / user
                            </div>
                            <div class="boxButton d-grid">
                                <button class="btn btn-secondary btn-lg me-4" @click="goTrial" style="width: 200px;">Sign Up</button>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-3 d-grid">
                        <div class="homeBox border shadow-sm">
                            <div class="text-center mb-1 boxDesc">
                                <span>Plan 1000</span>
                            </div>
                            <div class="text-center">
                                <span class="fw-bold text-uppercase">USD</span>
                                <span v-if="payInYear === false" class="boxTitle ps-1">120</span>
                                <span v-if="payInYear === true" class="boxTitle ps-1">1,325</span>
                                <span v-if="payInYear === false"> / month</span>
                                <span v-if="payInYear === true"> / year</span>
                            </div>
                            <div class="text-center"><hr /></div>
                            <div class="boxFontColor mb-2">
                                <fa icon="check" size="lg" class="me-3" color="#53BDEB"/><span class="fw-bold">1,000</span> credits / user
                            </div>
                            <div class="boxFontColor mb-2">
                                <fa icon="check" size="lg" class="me-3" color="#53BDEB"/><span class="fw-bold">30GB</span> storage / user
                            </div>
                            <div class="boxButton d-grid">
                                <button class="btn btn-secondary btn-lg me-4" @click="goTrial" style="width: 200px;">Sign Up</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <div id="pricing"></div>
    <div class="homeContainer pt-0">
        <div class="row">
            <div class="col-12 text-center secTitle">Plan & Pricing</div>

            <div class="col-12">
                <table>
                    <tr>
                        <td>
                            <div class="btn-group">
                                <a href="#pricing" class="btn btn-sm" :class="payOpt === 'monthly' ? 'btn-secondary text-white' : 'border'" @click="payOpt = 'monthly'">No Commitment</a>
                                <a href="#pricing" class="btn btn-sm" :class="payOpt === 'yearly' ? 'btn-secondary text-white' : 'border'" @click="payOpt = 'yearly'">1 Year Commitment</a>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>

            <div v-if="payOpt === 'yearly'" class="col-12 pt-3 pb-2 fst-italic text-grey">
                Price shown is yearly subscription with monthly recurring charges.
            </div>
            <div v-else class="col-12">&nbsp;</div>

            <div class="col-12 col-md-6 col-lg-3 d-grid">
                <div class="planBox border shadow-sm">
                    <div class="planHeader">&nbsp;</div>
                    <div class="text-center mb-1">
                        <h5>Free</h5>
                    </div>
                    <div class="text-center">
                        <span class="boxTitle ps-1">FOC</span>
                    </div>
                    <div class="text-center small text-grey">&nbsp</div>

                    <div class="boxButton d-grid">
                        <a href="https://app.signon.my/auth/signup?plan=FREE" class="btn btn-info text-white btn-lg btn-block text-white mb-5">Sign Up</a>
                    </div>

                    <div class="boxContent">
                        <table class="table table-borderless table-sm">
                            <tr>
                                <td width="25px" class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                <td><span class="fw-bold">30</span> sign requests / month</td>
                            </tr>
                            <tr>
                                <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                <td><span class="fw-bold">100MB</span> storage / user</td>
                            </tr>
                            <tr>
                                <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                <td><span class="fw-bold">5</span> members limit</td>
                            </tr>
                            <tr>
                                <td colspan="2" class="px-0 py-0 my-0"><hr /></td>
                            </tr>
                            <tr>
                                <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                <td>Document & signature validations</td>
                            </tr>
                            <tr>
                                <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                <td>Entity & user validations</td>
                            </tr>
                            <tr>
                                <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                <td>Sign documents</td>
                            </tr>
                            <tr>
                                <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                <td>Download folder & documents</td>
                            </tr>
                            <tr>
                                <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                <td>Audit trials on folder and individual documents</td>
                            </tr>
                            <tr>
                                <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                <td>Work as team</td>
                            </tr>
                            <tr>
                                <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                <td>Seal folder protection</td>
                            </tr>
                            <tr>
                                <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                <td>PDF workflow</td>
                            </tr>
                            <tr>
                                <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                <td>Referral incentives</td>
                            </tr>
                            <tr>
                                <td colspan="2" class="px-0 py-0 my-0"><hr /></td>
                            </tr>
                            <tr>
                                <td class="ps-0"><i class="fa-solid fa-xmark fa-xl text-grey"></i></td>
                                <td><span class="fw-bold">Shared rack</span> for team storage</td>
                            </tr>
                        </table>
                    </div>
                    
                </div>
            </div>

            <div class="col-12 col-md-6 col-lg-3 d-grid">
                <div class="planBoxReco shadow-sm">
                    <div class="planHeaderReco text-white">
                        Recommended
                    </div>
                    <div class="text-center mb-1 boxDesc">
                        <h5>Starter</h5>
                    </div>
                    <div class="text-center">
                        <span class="fw-bold text-uppercase">USD</span>
                        <span class="planBoxTitle ps-1">{{ arrPlan['STARTER'][payOpt] }}</span>
                        <span> / user</span>
                    </div>

                    <div v-if="payOpt === 'monthly'" class="text-center small text-grey">per month, no commitment</div>
                    <div v-if="payOpt === 'yearly'" class="text-center small text-grey">per month, 1-year commitment</div>

                    <div class="boxButton d-grid">
                        <a href="https://app.signon.my/auth/signup?plan=STARTER" class="btn btn-info text-white btn-lg btn-block text-white mb-5">Sign Up</a>
                    </div>

                    <div class="boxContent">
                        <table class="table table-borderless table-sm">
                            <tr>
                                <td width="25px" class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                <td><span class="fw-bold">99</span> sign requests / month</td>
                            </tr>
                            <tr>
                                <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                <td><span class="fw-bold">5GB</span> storage / user</td>
                            </tr>
                            <tr>
                                <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                <td><span class="fw-bold">99</span> members limit</td>
                            </tr>
                            <tr>
                                <td colspan="2" class="px-0 py-0 my-0"><hr /></td>
                            </tr>
                            <tr>
                                <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                <td>Document & signature validations</td>
                            </tr>
                            <tr>
                                <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                <td>Entity & user validations</td>
                            </tr>
                            <tr>
                                <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                <td>Sign documents</td>
                            </tr>
                            <tr>
                                <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                <td>Download folder & documents</td>
                            </tr>
                            <tr>
                                <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                <td>Audit trials on folder and individual documents</td>
                            </tr>
                            <tr>
                                <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                <td>Work as team</td>
                            </tr>
                            <tr>
                                <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                <td>Seal folder protection</td>
                            </tr>
                            <tr>
                                <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                <td>PDF workflow</td>
                            </tr>
                            <tr>
                                <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                <td>Referral incentives</td>
                            </tr>
                            <tr>
                                <td colspan="2" class="px-0 py-0 my-0"><hr /></td>
                            </tr>
                            <tr>
                                <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                <td><span class="fw-bold">Shared rack</span> for team storage</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-6 col-lg-3 d-grid">
                <div class="planBox border shadow-sm">
                    <div class="planHeader">
                        <span class="iconBlue isLink" @click="getTrial('STANDARD')">Start a trial</span>
                    </div>
                    <div class="text-center mb-1 boxDesc">
                        <h5>Standard</h5>
                    </div>
                    <div class="text-center">
                        <span class="fw-bold text-uppercase">USD</span>
                        <span class="planBoxTitle ps-1">{{ arrPlan['STANDARD'][payOpt] }}</span>
                        <span> / user</span>
                    </div>

                    <div v-if="payOpt === 'monthly'" class="text-center small text-grey">per month, no commitment</div>
                    <div v-if="payOpt === 'yearly'" class="text-center small text-grey">per month, 1-year commitment</div>

                    <div class="boxButton d-grid pt-0">
                        <a href="https://app.signon.my/auth/signup?plan=STANDARD" class="btn btn-info text-white btn-lg btn-block text-white mb-5">Sign Up</a>
                    </div>

                    <div class="boxContent">
                        <table class="table table-borderless table-sm">
                            <tr>
                                <td width="25px" class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                <td><span class="fw-bold">999</span> sign requests / month</td>
                            </tr>
                            <tr>
                                <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                <td><span class="fw-bold">30GB</span> storage / user</td>
                            </tr>
                            <tr>
                                <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                <td><span class="fw-bold">999</span> members limit</td>
                            </tr>
                            <tr>
                                <td colspan="2" class="px-0 py-0 my-0"><hr /></td>
                            </tr>
                            <tr>
                                <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                <td>Document & signature validations</td>
                            </tr>
                            <tr>
                                <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                <td>Entity & user validations</td>
                            </tr>
                            <tr>
                                <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                <td>Sign documents</td>
                            </tr>
                            <tr>
                                <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                <td>Download folder & documents</td>
                            </tr>
                            <tr>
                                <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                <td>Audit trials on folder and individual documents</td>
                            </tr>
                            <tr>
                                <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                <td>Work as team</td>
                            </tr>
                            <tr>
                                <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                <td>Seal folder protection</td>
                            </tr>
                            <tr>
                                <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                <td>PDF workflow</td>
                            </tr>
                            <tr>
                                <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                <td>Referral incentives</td>
                            </tr>
                            <tr>
                                <td colspan="2" class="px-0 py-0 my-0"><hr /></td>
                            </tr>
                            <tr>
                                <td class="ps-0"><i class="fa-solid fa-check fa-lg iconBlue"></i></td>
                                <td><span class="fw-bold">Shared rack</span> for team storage</td>
                            </tr>
                        </table>
                    </div>

                </div>
            </div>

            <div class="col-12 col-md-6 col-lg-3">
                <div class="planBox border shadow-sm">
                    <div class="planHeader">&nbsp;</div>
                    <div class="text-center mb-1">
                        <h5>Enterprise</h5>
                    </div>
                    <div class="text-center mt-4" style="padding-bottom: 16px">
                        <div class="fw-bold">Price</div>
                        <div class="fw-bold">Upon Request</div>
                    </div>
                    <div class="text-center small text-grey">&nbsp</div>

                    <div class="boxButton d-grid">
                        <button class="btn btn-info text-white btn-lg btn-block mb-5" data-bs-toggle="modal" data-bs-target="#mdlTalk">Talk to Us</button>
                    </div>

                    <div class="boxContent text-center mb-3" style="line-height: 2">
                        The final price is based on customization.<br />Please reach out to discuss your specific needs.
                    </div>
                    
                </div>
            </div>

            <div class="col-12 mt-2 fst-italic fw-light">
                <div><i class="fa-solid fa-star-of-life fa-xs text-grey me-1"></i> FREE one-time 30 credits upon successful KYC.</div>
            </div>
        </div>
    </div>

    <div class="homeContainer pt-0">
        <div class="row">
            <div class="col-12 text-center secTitle">FAQ</div>

            <div class="accordion" id="myFaq">

                <div class="accordion-item">
                    <div class="accordion-header" id="faq_1">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqc_1" aria-expanded="false" aria-controls="faqc_1">
                            <span class="faqTitle">Is SignOn eSignature free?</span>
                        </button>
                    </div>
                    <div id="faqc_1" class="accordion-collapse collapse" aria-labelledby="faq_1" data-bs-parent="#myFaq">
                        <div class="accordion-body">Yes, it is always free to sign via SignOn. We offer a free online account for normal users with reasonable usage, try it <a href="https://app.signon.my/auth/signup"><u>here</u></a>. There is an option to upgrade to paid service.</div>
                    </div>
                </div>

                <div class="accordion-item">
                    <div class="accordion-header" id="faq_2">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqc_2" aria-expanded="false" aria-controls="faqc_2">
                            <span class="faqTitle">Can I share my work with my teammates?</span>
                        </button>
                    </div>
                    <div id="faqc_2" class="accordion-collapse collapse" aria-labelledby="faq_2" data-bs-parent="#myFaq">
                        <div class="accordion-body">Yes, you may switch from Personal to Team mode anytime when you are ready to get your teammates on board. You can assign them as co-editors to share your workload. It is free.</div>
                    </div>
                </div>

                <div class="accordion-item">
                    <div class="accordion-header" id="faq_3">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqc_3" aria-expanded="false" aria-controls="faqc_3">
                            <span class="faqTitle">When do I need to upgrade to paid service?</span>
                        </button>
                    </div>
                    <div id="faqc_3" class="accordion-collapse collapse" aria-labelledby="faq_3" data-bs-parent="#myFaq">
                        <div class="accordion-body">We offer paid service for heavy users, usually for organizations with higher demand of usage and storage space. There are selections of plans that cater for your needs, check it out <u>here</u>.</div>
                    </div>
                </div>

                <div class="accordion-item">
                    <div class="accordion-header" id="faq_4">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqc_4" aria-expanded="false" aria-controls="faqc_4">
                            <span class="faqTitle">What is a rack, folder, and document in SignOn?</span>
                        </button>
                    </div>
                    <div id="faqc_4" class="accordion-collapse collapse" aria-labelledby="faq_4" data-bs-parent="#myFaq">
                        <div class="accordion-body">A folder is a container for documents that you send to a recipient (signee) to sign. A folder can keep more than one document, and can circulate to more than one signee. Folders have statuses (i.e. Work In Progress, Circulating, Sealed, Delete) and contain information about the sender and timestamps that track the eSigning progress. You may upgrade your rack to store your growing folders.</div>
                    </div>
                </div>

                <div class="accordion-item">
                    <div class="accordion-header" id="faq_5">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqc_5" aria-expanded="false" aria-controls="faqc_5">
                            <span class="faqTitle">How safe is SignOn?</span>
                        </button>
                    </div>
                    <div id="faqc_5" class="accordion-collapse collapse" aria-labelledby="faq_5" data-bs-parent="#myFaq">
                        <div class="accordion-body">All of SignOn's offerings include the highest levels of trust and security, industry leading user experience, and the flexibility to grow as your needs evolve. All your documents are encrypted and splitted to pieces before distributing</div>
                    </div>
                </div>

                <div class="accordion-item">
                    <div class="accordion-header" id="faq_6">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqc_6" aria-expanded="false" aria-controls="faqc_6">
                            <span class="faqTitle">What distinguishes us from competitors?</span>
                        </button>
                    </div>
                    <div id="faqc_6" class="accordion-collapse collapse" aria-labelledby="faq_6" data-bs-parent="#myFaq">
                        <div class="accordion-body">
                            <div class="mb-2">SignOn requires validated email for the eSigning process at the minimum security level. However, if requested by the sender, signees are required to be KYCed, or even upload the footage of affirmations or oath ceremonies. Senders may attach the scanned documents to the same folder for file keeping purposes.</div>
                            <div>SignOn offers validation and verification services on signees and organizations to minimize the phishing and fraudulent attempts. Our free online tool <a href="https://app.signon.my/doc/verify"><u>Verify Doc</u></a> that is available 24x7 checks the fingerprints of the documents to ensure your local digital copy is the same as the source copy.</div>
                        </div>
                    </div>
                </div>

                <div class="accordion-item">
                    <div class="accordion-header" id="faq_7">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqc_7" aria-expanded="false" aria-controls="faqc_7">
                            <span class="faqTitle">How to integrate SignOn with my app?</span>
                        </button>
                    </div>
                    <div id="faqc_7" class="accordion-collapse collapse" aria-labelledby="faq_7" data-bs-parent="#myFaq">
                        <div class="accordion-body">We provide free API and Open Source resources at the Developer Center. You may programme your apps to send quotations, invoices, offer letters and many other interactive eSigning processes with our API.</div>
                    </div>
                </div>

                <div class="accordion-item">
                    <div class="accordion-header" id="faq_8">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqc_8" aria-expanded="false" aria-controls="faqc_8">
                            <span class="faqTitle">How to incorporate workflow with eSignature?</span>
                        </button>
                    </div>
                    <div id="faqc_8" class="accordion-collapse collapse" aria-labelledby="faq_8" data-bs-parent="#myFaq">
                        <div class="accordion-body">eSignature with workflow helps improve your productivity, enhance communication, reduce errors and delays, increase efficiency and reduce cost in your organizations. You may use our built-in workflow designer to streamline your operational processes. This feature is available soon.</div>
                    </div>
                </div>

                <div class="accordion-item">
                    <div class="accordion-header" id="faq_9">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqc_9" aria-expanded="false" aria-controls="faqc_9">
                            <span class="faqTitle">Will my monthly/annual subscription be renewed automatically?</span>
                        </button>
                    </div>
                    <div id="faqc_9" class="accordion-collapse collapse" aria-labelledby="faq_9" data-bs-parent="#myFaq">
                        <div class="accordion-body">Yes, your subscription will automatically be renewed until you let us know that you'd like to change your plan or cancel your account.</div>
                    </div>
                </div>

                <div class="accordion-item">
                    <div class="accordion-header" id="faq_10">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqc_10" aria-expanded="false" aria-controls="faqc_10">
                            <span class="faqTitle">How do I cancel or downgrade my subscription?</span>
                        </button>
                    </div>
                    <div id="faqc_10" class="accordion-collapse collapse" aria-labelledby="faq_10" data-bs-parent="#myFaq">
                        <div class="accordion-body">You can cancel or downgrade your account anytime in your preferences section.</div>
                    </div>
                </div>

            </div>

        </div>
    </div>


    <!-- Enerson requested to hide it temporary
    <div class="homeContainer pt-4">
        <div class="row">
            <div class="col-12 text-center secTitle">Trusted partners</div>
            <div class="imgDesc text-center">Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque ex magni, ipsa nihil accusamus laborum blanditiis quibusdam? Dolor dolores ut fuga asperiores. Ea ipsam et hic qui laborum quo eius.</div>
        </div>
    </div>
    
    <div class="row mt-4">
        <div class="col-12 col-lg-2 d-grid">
            <div class="logoBox border">
                <table style="height: 100%;" align="center">
                    <tbody>
                        <tr>
                            <td class="align-middle"><img alt="Vue logo" src="../assets/home/logo_genusis.png" class="img-fluid"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="col-12 col-lg-2 d-grid">
            <div class="logoBox border">
                <table style="height: 100%;" align="center">
                    <tbody>
                        <tr>
                            <td class="align-middle"><img alt="Vue logo" src="../assets/home/logo_ac.jpeg" class="img-fluid"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="col-12 col-lg-2 d-grid">
            <div class="logoBox border">
                <table style="height: 100%;" align="center">
                    <tbody>
                        <tr>
                            <td class="align-middle"><img alt="Vue logo" src="../assets/home/logo_algae.jpeg" class="img-fluid"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="col-12 col-lg-2 d-grid">
            <div class="logoBox border">
                <table style="height: 100%;" align="center">
                    <tbody>
                        <tr>
                            <td class="align-middle"><img alt="Vue logo" src="../assets/home/logo_ggv.jpeg" class="img-fluid"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="col-12 col-lg-2 d-grid">
            <div class="logoBox border">
                <table style="height: 100%;" align="center">
                    <tbody>
                        <tr>
                            <td class="align-middle"><img alt="Vue logo" src="../assets/home/logo_thegigabit.jpeg" class="img-fluid"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div> -->

    <div class="row">
        <div class="col-12 footer">
            <Footer />
        </div>
    </div>

    <!-- Enerson requested to hide floating button temporary
    <span id="floatContainer" class="shadow-sm">
        <fa icon="phone" id="floatButton" />
    </span> -->
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->

    <!-- Modal: TalkToUs --> 
    <div class="modal fade" id="mdlTalk" ref="mdlTalk" tabindex="-1" aria-labelledby="AddNote" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Talk to us</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 mb-3">
                            <table class="table table-borderless">
                                <tbody>
                                    <tr>
                                        <td><input type="text" class="form-control" v-model="reqName" placeholder="Name *"></td>
                                    </tr>
                                    <tr>
                                        <td><input type="text" class="form-control" v-model="reqEmail" placeholder="Email *"></td>
                                    </tr>
                                    <tr>
                                        <td><textarea class="form-control" v-model="reqRemark" placeholder="Request *"></textarea></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" style="justify-content: space-between">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <i class="fa-solid fa-xmark me-2"></i>Cancel
                    </button>
                    <button class="btn btn-secondary me-2" data-bs-dismiss="modal" >
                        <i class="fa-solid fa-paper-plane-top me-2"></i>Submit
                    </button>
                </div>
            </div><!-- modal-content -->
        </div><!-- modal-dialog -->
    </div><!-- Modal End-->
  
</template>

<script>
import { ref, inject, onMounted, computed } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Footer from '@/components/Footer.vue'
import Alert from '@/components/Alert.vue'
import { useRouter, useRoute } from 'vue-router'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import Avatar from '@/components/Avatar.vue'
import { useStore } from '@/stores/store'

export default {
  name: 'Home',
  components: { Alert, TopNavigation, Footer, Popper, Avatar },
  setup() {
        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs

        const { getDisplayName, getAvatar, getUserIsVerified, getUserId, getUserLastLogin } = useStore()

        const docId = ref(null)
        // const docId = ref('_4gP53zir7kHGaUG6Iams')
        const showDetail = ref(false) // false
        const doc = ref({})
        const isCreator = ref(true) // false
        const isPrivate = ref(true) // false
        const signees = ref([])
        const viewers = ref([])
        const validSession = ref(false)
        const payInYear = ref(false)

        const payOpt = ref('yearly')    // monthly/yearly

        const arrPlan = ref({
            STARTER: {
                monthly: 6,
                yearly: 5,
                user: 99
            },
            STANDARD: {
                monthly: 21.6,
                yearly: 18,
                user: 999
            }
        })

        // const exclusiveImg = ref(null)

        const goTrial = () => {
            window.location.href = 'https://app.signon.my/auth/signup'
        }

        const goVerifyDoc = () => {
            window.location.href = 'https://app.signon.my/doc/verify'
        }

        const searchDocById = async () => {
            if (docId.value === null || docId.value === '') {
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: 'Please enter document ID.'
                })

            } else {
                await axios.get( '/signon/signee/document/' + docId.value)
                    .then((res) => {
                        // console.info('searchDocById ', JSON.stringify(res.data.data))
                        console.info('searchDocById signees', JSON.stringify(res.data.data.signees))
                        // console.info('searchDocById signee', JSON.stringify(res.data.data.signee))

                        if (res.data.status === 1) {
                        showDetail.value = true
                        doc.value = res.data.data
                        isPrivate.value = res.data.data.isPrivate

                        if (res.data.data.signee.privileges && res.data.data.signee.privileges.includes('creator') !== undefined) {
                            isCreator.value = res.data.data.signee.privileges.includes('creator')
                        }

                        if (res.data.data.signees) {
                            res.data.data.signees.forEach((item, idx) => {
                                //    console.info('item', JSON.stringify(item))
                                if (item.privileges) {
                                    console.info('-- privileges', JSON.stringify(item))
                                    //    console.info('item', item.privileges)
                                    if (item.privileges.includes('signee')) {
                                        signees.value.push(item)
                                    }
                                    if (item.privileges.includes('viewer')) {
                                        viewers.value.push(item)
                                    }
                                } else {
                                    console.info('x privileges', JSON.stringify(item))
                                    viewers.value.push(item)
                                }
                            })

                            // console.info('signees', JSON.stringify(signees.value))
                            // console.info('viewers', JSON.stringify(viewers.value))
                        }
                        } 
                    })
                    .catch((error) => {
                        if (error.status === 'record_not_found') {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: 'Record not found. (' + error.code + ')'
                            })

                        } else {
                            console.log('FAILURE', error)
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.convError(error)
                            })
                        }
                    })
            }
        }

        const updIsPrivate = (val) => {
            
            const p = {
                isPrivate: val
            }

            axios.put( '/signon/' + doc.value.projectId + '/' + docId.value, p)
                .then((res) => {

                    if (res.data.status === 1) {
                        isPrivate.value = val // after update axios success

                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Document is updated to " + (val ? "private." : "public.")
                        })
                    } 
                })
                .catch((error) => {
                    console.log('FAILURE', error)
                    alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: func.convError(error)
                    })
                })
        }

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const chkSession = async () => {
            try {
                const response = await axios.get('/auth/session');

                // console.info('TOPNav chkSession', JSON.stringify(response.data.data))
                if( response.data.status === 1) {
                    validSession.value = true
                   
                } else {
                    validSession.value = false
                }

            } catch (error) {
                validSession.value = false
            }
        }

        /* computed: {
            exclusiveImg() {
                return {
                    backgroundImage: `url${require('../assets/home/rocket.jpg')}`
                }
            }
        } */

        onMounted(() => {

            // console.info('HOME onMounted', config.hostname)
            func.clearPrevLocalStorage()
            // tmp hide API | chkSession()

            // First time login
            if (route.query.fl === true || route.query.fl === 'true') {
                alert.value.push({
                    class: "success",
                    title: "SUCCESS",
                    message: "Welcome to SignOn."
                })
            }

            // Not first time login
            if (route.query.fl === false || route.query.fl === 'false') {
                if (route.query.l) { 
                    alert.value.push({
                        class: "success",
                        title: "SUCCESS",
                        message: "Welcome back. Last login " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.l), new Date()) + ", last active " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.a), new Date()) + "."
                    })
                }
            }

           /*  exclusiveImg.value = computed({
                backgroundImage: `url${require('../assets/home/rocket.jpg')}`
            }) */
        })

        return { 
            alert, closeAlert, route, router, func, docId, searchDocById, showDetail, doc, 
            signees, viewers, validSession, 
            isCreator, isPrivate, updIsPrivate,
            getDisplayName, getAvatar, getUserIsVerified, getUserId, getUserLastLogin, goVerifyDoc, goTrial, payInYear, payOpt, arrPlan, 
            /* exclusiveImg */
        }
    }
}
</script>


<style>
.exclusiveImg {
    height: 300px;
    border-radius: 10px 0px 0px 10px;
    background: url('../assets/home/rocket.jpg') no-repeat center;
}

.whoWeAreImg {
    height: 300px;
    border-radius: 10px 0px 0px 10px;
    background: url('../assets/home/penmanship.jpg') no-repeat center;
}

#floatContainer {
    display: flex; 
    flex-direction: column;  
    justify-content: flex-end;  
    align-items: center;  
    user-select: none;  
    position: fixed;  
    bottom: 30px; 
    right: 30px;
    padding: 18px;
    /* background-color: #53BDEB; */
    border-radius: 35px;
    
  background-color: #212529;
  border-color: #212529;
}

#floatContainer:hover {
    /* background-color: #53B1D9; */
    background-color: #0C1116;
    cursor: pointer;
}

#floatButton {
    font-size: 26px;
    color: #ffffff;
}

.greyContainer {
  margin-top: 30px;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: var(--navbar-light);
}


.greyContent {
    width: 75%; 
    margin: 0px auto; 
    font-size: 17px;
    font-family: "Inter-Medium", sans-serif;
    font-weight: 400;
    line-height: 1.43;
}

.homeContainer {
  width: 75%; 
  margin: 55px auto 0px auto;
}

.homeBox {
  border-radius: 10px;
  padding: 25px;
  margin-bottom: 25px;
  margin-left: 2px;
  margin-right: 2px;
  color: #6E777E;
}

.homeBlueBox {
  border-radius: 10px;
  padding: 25px;
  margin-bottom: 25px;
  margin-left: 2px;
  margin-right: 2px;
  background-color: #53BDEB;
  color: var(--bgcolor-body-color);
}

.imgTitle {
    font-size: 64px;
    font-weight: bold;
    color: #6E777E;
    font-family: "TT-Firs-Neue-Demi-Bold", sans-serif;
    font-weight: 600;
    line-height: 74px;
    letter-spacing: -0.02em;
    margin-bottom: 28px;
}

.imgDesc {
    font-size: 22px;
    color: #6E777E;
    opacity: 0.6;
    font-family: "Inter-Medium", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    margin-bottom: 28px;
}

.secTitle {
    font-size: 64px;
    font-weight: bold;
    color: #6E777E;
    font-family: "TT-Firs-Neue-Demi-Bold", sans-serif;
    font-weight: 600;
    line-height: 74px;
    letter-spacing: -0.02em;
    padding-bottom: 38px;
}

.boxContent {
    margin-left: 20px;
    margin-right: 20px;
}

.boxTitle {
    font-size: 50px;
    font-weight: bold;
    font-family: "TT-Firs-Neue-Demi-Bold", sans-serif;
    line-height: 74px;
    letter-spacing: -0.02em;
    color: #6E777E;
}

.boxDesc {
    font-size: 16px;
    font-family: "Inter-Medium", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    color: #8F9093;
}

.boxButton {
    margin-top: 40px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    height: 50px;
}

.boxTable {
    font-size: 17px;
    font-family: Inter,sans-serif;
    font-weight: 500;
    line-height: 1.5;
    color: #8F9093;
    height: 80px;
}

.lblLogo {
    font-size: 18px;
    color: var(--section-title);
    font-family: "Inter-Bold", sans-serif;
}

.lblStep {
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 8px;
    color: #53BDEB;
    font-family: "Inter-Medium", sans-serif;
    font-size: 17px;

}

.lblStepTitle {
    font-size: 34px;
    font-weight: 400;
    line-height: 1.43;
    color: var(--section-title);
    font-family: "Inter-Medium", sans-serif;
    margin-bottom: 10px;
}

.lblStepDesc {
    color: var(--section-title);
    margin: 0;
    font-size: 17px;
    font-family: "Inter-Medium", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    height: 180px;
}

.lblStepSubTitle {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.43;
    color: #6E777E;
    font-family: "Inter-Medium", sans-serif;
    /* margin-bottom: 10px; */
}

.lblStepSubDesc {
    color: #6E777E;
    margin: 0;
    font-size: 17px;
    font-family: "Inter-Medium", sans-serif;
    font-weight: 400;
    line-height: 1.43;
}

.numberCicle {
    width: 15px;
    height: 15px;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid var(--bs-primary);
    font-size: 18px;
    font-weight: 600;
}

.boxFlow {
    border-radius: 15px;
    color: #6E777E;
    font-size: 17px;
    font-family: "Inter-Medium", sans-serif;
    font-weight: 400;
    line-height: 1.43;
}

.boxTextFlow {
    padding-left: 10px;
    padding-right: 10px;
}

.faqTitle {
    color: var(--text-grey-link);
}

.footer {
    margin-top: 80px;
    margin-bottom: 0px;
    padding: 0px 0px 0px 0px;
    background-color: var(--navbar-light);
    border-top-color: var(--toolbar-hr);
}

.logoBox {
  border-radius: 10px;
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 25px;
  color: #1F2226;
  height: 105px;
  text-align: center;
  vertical-align: middle;
}

.logoBox > table > tbody > tr > td > img {
    max-height: 80px;
}


.step-number .bg-gray-1 {
    margin-right: 10px;
    /* border: 1px solid #def7f0; */
    /* border: 1px solid var(--bs-primary);
     */
     border: 1px solid red;
}
.step-number {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 40px;
    height: 40px;
    padding: 10px;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 9999px;
    /* color: #160647; */
    color: var(--text-islink-hover);
    line-height: 1;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0;
    text-transform: uppercase;
}

.bg-gray-1 {
    /* background-color: #f8fcfb; */
    background-color: var(--accordion-bg);
    /* border: 1px solid #DDF2F8; */
    border: 1px solid var(--number-circle);
}

.planBox {
    border-radius: 10px;
    /* padding: 0px 22px 25px 22px; */
    padding: 0px;
    margin-bottom: 25px;
    margin-left: 2px;
    margin-right: 2px;
    color: #6E777E;
}

.planBoxReco {
    border-radius: 10px;
    /* padding: 25px 22px; */
    padding: 0px;
    margin-bottom: 25px;
    margin-left: 2px;
    margin-right: 2px;
    color: #6E777E;
    border: 1px solid var(--bs-primary);
}

.boxButton {
    /* margin-top: 50px; */
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    /* margin-bottom: 0px; */
    /* padding-bottom: 0px; */
    height: 70px;
}

.planBoxButtonWithTrial {
    margin-top: 10px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    height: 50px;
}

.planHeader {
    background-color: #f8f9fa;
    padding: 5px 25px;
    margin-bottom: 15px;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    text-align: center;
}

.planHeaderReco {
    background-color: #53BDEB;
    padding: 5px 25px;
    margin-bottom: 15px;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    text-align: center;
}

.planBoxTitle {
    font-size: 50px;
    font-weight: bold;
    font-family: "TT-Firs-Neue-Demi-Bold", sans-serif;
    line-height: 74px;
    letter-spacing: -0.02em;
    color: #6E777E;
}

.iconBlue {
    color: #53BDEB;
}

@media (max-width: 500px) {

    .greyContent {
        width: 100%;
    }

    .homeContainer {
      width: 100%;
      margin: 40px auto 40px auto;
      /* border: 1px solid green; */
    }

    .imgTitle {
        font-size: 39px;
        line-height: 41px;
        letter-spacing: -0.01em;
    }

    .imgDesc, .boxDesc {
        font-size: 20px;
        font-family: "Inter-Medium", sans-serif;
        line-height: 1.5;
    }

    /* .secTitle, .boxTitle {
        font-size: 39px;
        line-height: 41px;
        letter-spacing: -0.01em;
    } */

    .footer {
        margin-top: 40px;
        padding: 40px 0px;
    }

    .lblStepSubTitle {
        font-size: 18px;
        font-weight: 600;
        line-height: 1.43;
        color: #6E777E;
        font-family: "Inter-Medium", sans-serif;
        margin-bottom: 10px;
            padding-top: 5px;
        background-color: yellow;
    }

    .lblStepSubDesc {
        color: #6E777E;
        margin: 0;
        font-size: 18px;
        font-family: "Inter-Medium", sans-serif;
        font-weight: 400;
        line-height: 1.43;
    }

    .boxTextFlow {
        padding-left: 0px;
        padding-right: 0px;
    }
}
</style>
