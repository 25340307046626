<template>
        <!-- <div style="padding: 40px 25px 25px 25px;"> -->
        <div style="padding-left: 25px; padding-right: 25px">

            <div class="row">
                <div class="col-lg-4" style="padding-top: 40px; padding-bottom: 25px">
                    <div class="boxFlow border shadow-sm bg-white p-3 text-center">
                        <div class="mb-2"><fa icon="signature" class="bg-info" style="font-size: 30px; padding: 10px 8px; border-radius: 25px" /></div>
                        <div class="footerTitle pb-0 mb-0">Get yourself a free account today</div>
                        <div class="footerDesc pb-2">No credit card required.</div>
                        <div>
                            <a href="https://app.signon.my/auth/signup" class="btn btn-info btn-lg text-white">Get Started</a>
                        </div>
                    </div>
                </div>

                

                <div class="col-8 px-4" style="padding-top: 80px">
                    <div class="row px-4">
                        <div class="col">
                            <div class="footerTitle">ABOUT US</div>
                            <div class="footerDesc"><span @click="navigateHome('#secWhoWeAre')">Who we are</span></div>
                            <div class="footerDesc"><span @click="navigateHome('#secExclusive')">Exclusive Pre-launch Deal</span></div>
                            <div class="footerDesc"><a href="mailto: wecare@signon.my">Feedback and Suggestion</a></div>
                            <div class="footerDesc"><span @click="navigateCompany()">Company Information</span></div>
                            <div class="footerDesc mb-4"><span @click="navigateHome('#pricing')">Plan & Pricing</span></div>
                        </div>

                        <div class="col">
                            <div class="footerTitle">RESOURCES</div>
                            <div class="footerDesc"><span @click="navigateHome('#')">Developer Center</span></div>
                            <div class="footerDesc"><a href="mailto: wecare@signon.my" class="isLink">Get Support</a></div>
                            <div class="footerDesc mb-4"><span @click="$router.push({ path: '/legality' })">Legality Guide</span></div>
                        </div>
                    </div>
                </div>

                <!-- <div class="col-lg-4 ps-4" style="padding-top: 80px">
                    <div class="footerTitle">ABOUT US</div>
                    <div class="footerDesc"><a href="#secWhoWeAre">Who we are</a></div>
                    <div class="footerDesc"><a href="#secExclusive">Exclusive Pre-launch Deal</a></div>
                    <div class="footerDesc mb-4"><a href="mailto: wecare@signon.my">Feedback and Suggestion</a></div>
                </div>

                <div class="col-lg-4 ps-4" style="padding-top: 80px">
                    <div class="footerTitle">RESOURCES</div>
                    <div class="footerDesc">Developer Center</div>
                    <div class="footerDesc"><a href="mailto: wecare@signon.my">Get Support</a></div>
                    <div class="footerDesc mb-4" @click="$router.push({ path: '/legality' })">Legality Guide</div>
                </div> -->

                <div class="col-12"><hr/></div>

                <div class="col-lg-6 pb-3">Copyright &copy; 2025 SignOn. All rights reserved.</div>
                <div class="col-lg-6 text-end pb-3">
                    <span class="pe-4 isLink" @click="$router.push({ path: '/terms' })">Terms of Service</span>
                    <span class="isLink" @click="$router.push({ path: '/privacy' })">Privacy Policy</span>
                </div>
            </div>

        </div>
</template>

<script>
import { useRouter, useRoute } from 'vue-router'

export default {
    setup() {
        const route = useRoute()
        const router = useRouter()

        const navigateHome = (pathRoute) => {
            window.location.href = window.location.origin + pathRoute
        }

        const navigateLegal = () => {
            // window.location.href = window.location.origin + '/legality#legal'
            window.location.href = window.location.origin + '/legality'
        }

        const navigateTerms = () => {
            // window.location.href = window.location.origin + '/terms#terms'
            window.location.href = window.location.origin + '/terms'
        }

        const navigatePrivacy = () => {
            // window.location.href = window.location.origin + '/privacy#privacy'
            window.location.href = window.location.origin + '/privacy'
        }

        const navigateCompany = () => {
            window.location.href = window.location.origin + '/company'
        }

        return { route, router, navigateHome, navigateLegal, navigateTerms, navigatePrivacy, navigateCompany }
    }
}
</script>

<style>

/* .footer {
    margin-top: 80px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding: 60px 0px 0px 0px;
    background-color: var(--navbar-light);
    border-top-color: var(--toolbar-hr);
} */

.footerTitle {
    font-size: 17px;
    font-family: Inter,sans-serif;
    font-weight: 600;
    line-height: 1.5;
    color: #6E777E;
    margin-bottom: 10px;
    
}

.footerDesc {
    font-size: 17px;
    font-family: Inter,sans-serif;
    font-weight: 500;
    line-height: 1.5;
    color: #6C757D;
    margin-bottom: 5px;
    cursor: pointer;
}

.footerDesc:hover {
    color: #53BDEB;
    cursor: pointer;
}

</style>